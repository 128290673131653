import * as React from "react"

const Information = ({ data }) => {
  var contact = data.contact
  return (
    <div className="contact-information m-bb1 m-black">
      <p className="op-50">Contact</p>
      <div className='m-bb1 m-mb40'>
        <div className="medium mb40 m-mb20">
          <a href={"tel:" + contact.phone} className="link ul-link">
            {contact.phone}
          </a>
          <br />
          <a href={"mailto:" + contact.email} className="link ul-link">
            {contact.email}
          </a>
        </div>
      </div>
      <p className="op-50">Address</p>
      <div className='m-bb1 m-mb40'>
        <div
          className="medium mb40 m-mb20"
          dangerouslySetInnerHTML={{ __html: contact.address }}
        />
      </div>
      <p className="op-50">Social</p>
      <div className='m-bb1 m-mb40'>
        <div className="medium mb40 m-mb20">
          <a className="link" target='_blank' rel="noreferrer" href={contact.instagram}>
            <span className="icon instagram mr10" />
            <span className='ul-link'>Instagram</span>
          </a>
          <br />
          <a className="link" target='_blank' rel="noreferrer" href={contact.facebook}>
            <span className="icon facebook mr10" />
            <span className='ul-link'>Facebook</span>
          </a>
          <br />
          <a className="link" target='_blank' rel="noreferrer" href={contact.linkedin}>
            <span className="icon linkedin mr10" />
            <span className='ul-link'>Linkedin</span>
          </a>
        </div>
      </div>
      <p className="op-50">Careers</p>
      <div className="careers max-250 medium m-mb40" dangerouslySetInnerHTML={{ __html: contact.careers }} />
    </div>
  )
}

export default Information
