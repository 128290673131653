import React, { useEffect } from "react"
import { gsap } from "gsap"

const Title = ({ first, second, symbol, delay=0 }) => {
  useEffect(() => {
    gsap.fromTo(
      ".title--main .first span",
      { y: "110%", skewY: "3deg" },
      { y: 0, skewY: 0, duration: 1, delay: delay+0.25, ease: "Power3.easeOut" }
    )
    gsap.fromTo(
      ".title--main .second span",
      { y: "110%", skewY: "3deg" },
      { y: 0, skewY: 0, duration: 1, delay: delay+0.5, ease: "Power3.easeOut" }
    )
  })
  return (
    <div className="title--main p40">
      <p className="h1 m0 overflow-hidden first">
        <span className="inline">
          {symbol ? <span className="symbol" /> : ""} {first}
        </span>
        <br />
      </p>
      {second ? (
        <p className="h1 m0 overflow-hidden second">
          <span
            className="inline"
            dangerouslySetInnerHTML={{ __html: second }}
          />
          <br />
        </p>
      ) : (
        ""
      )}
    </div>
  )
}

export default Title
