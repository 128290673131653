import * as React from "react"
import Form from "../components/form"

const ContactForm = () => (
  <div className="contact-form">
    <p className="h3 m0 m-h4 m-mt40">Leave an enquiry</p>
    <Form />
  </div>
)

export default ContactForm
