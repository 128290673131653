import * as React from "react"

class EnquiryForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      formSubmitted: false,
      message: ""

    }
    
  }
  componentDidMount() {
    document.querySelector("form").addEventListener("submit", this.handleSubmit)
  }
  

  handleSubmit = e => {
    e.preventDefault()
    let myForm = document.getElementById("contact")
    let formData = new FormData(myForm)
    if(formData.get('First Name') && formData.get('Email') && formData.get('Number')){
      console.log('test fetch')
      fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
      })
      .then(() => {
        const form = document.querySelector("form")
        form.classList.add("fade-out")
        setTimeout(() => {
          this.setState({ formSubmitted: true },()=>{
            const thankYou = document.querySelector("#thank-you")
            setTimeout(()=>{
              thankYou.classList.add("fade-in-thank-you")
            },100)
           
          })
        }, 1250)
      })
    .catch(error => console.error(error))
    document.querySelector("form").addEventListener("submit", this.handleSubmit);
    }
  }

  render() {
    const formSubmitted = this.state.formSubmitted
    
    if(!formSubmitted){
    return (
      <div className="mt40">
        <form method="POST" data-netlify="true" id="contact" className="contact--form">
            <input type="hidden" name="form-name" value="contact" />
            <div className='flex m-wrap'>
              <div className='w-50 flex m-100 m-mb20'>
                <input required={true} name="First Name" type="text" className="p20 w-100 pl0 mb20 bb1 mr40 m-m0 input" placeholder="First Name*"/>
              </div>
              <div className='w-50 flex m-100 m-mb20'>
                <input required={true} name="Last Name" type="text" className="p20 w-100 pl0 mb20 bb1 mr40 m-m0 input" placeholder="Last Name*"/>
              </div>
            </div> 
            <div className='flex m-wrap'>
              <div className='w-50 flex m-100 m-mb20'>
                <input required={true} name="Email" type="text" className="p20 w-100 pl0 mb20 bb1 mr40 m-m0 input" placeholder="Email*" />
              </div>
              <div className='w-50 flex m-100 m-mb20'>
                <input required={true} name="Number" type="text" className="p20 w-100 pl0 mb20 bb1 mr40 m-m0 input" placeholder="Number*" />
              </div>
            </div>
            
            <div className='flex m-wrap'>
              <div className='w-50 flex m-100 m-mb20'>
                <input name="Subject" type="text" className="p20 w-100 pl0 mb20 bb1 mr40 m-m0 input" placeholder="Subject"/>
              </div>
              <div className='w-50 flex m-100 m-mb20'>
                <input name="Enquiry Type" type="text" className="p20 w-100 pl0 mb20 bb1 mr40 m-m0 input" placeholder="Enquiry Type"/>
              </div>
            </div>
            
            <div className='text-area flex mt40'>
              <textarea onChange={(e) => this.setState({message:e.target.value})} value={this.state.message} className="textarea input w-100" name="message" placeholder='Message...' type="text" id="message" />
            </div>

            <div className="submit mt40">
              <button type="submit" className="submit-button input">Submit <span className='arrow ml20'/></button>
            </div>
          </form>
      </div>
    )}else{
      return <div className="form-section mt40 thank-you h2" id="thank-you"> Thank You for submitting. We'll be in touch soon</div>
    }
  }
}

export default EnquiryForm
