import * as React from "react"
import Layout from "../components/layout"
import Spacer from "../components/spacer"
import Title from "../components/titleAnimate"
import ContactInformation from "../components/contactInformation"
import Form from "../components/contactForm"
import Seo from "../components/seo"
import { graphql } from "gatsby"

const ContactPage = ({ data }) => (
  <Layout>
    <Spacer />
    <Title first="Interested in starting" second="a project with us?" />
    <Spacer />
    <div className="flex p40 mb80 m-wrap">
      <div className="w-30 m-100">
        <ContactInformation data={data} />
      </div>
      <div className="w-70 m-100">
        <Form />
      </div>
    </div>
  </Layout>
)

export default ContactPage

export const Head = () => <Seo title="Contact Us" />

export const query = graphql`
  query ContactQuery {
    contact: datoCmsContact {
      phone
      email
      address
      instagram
      facebook
      linkedin
      careers
    }
  }
`
